import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import {
  Alert,
  Typography,
  Link,
  Grid,
  Box,
  LinearProgress,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  FormButton,
  FormSelect,
  FormCheckbox,
  FormDatePicker,
  TrackableProgress,
} from "components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { addDays, format as DateFormat } from "date-fns";
import {
  selectIsFetching as selectIsOrganizationFetching,
  fetchOrganizationsForEnvironmentRequested,
  selectEnvironmentOrganizations,
  resetData as refreshOrganizationList,
  selectIsLoaded as selectIsOrganizationLoaded,
} from "stores/organizations/organizationsSlice";
import {
  fetchBranchesRequested,
  selectBranches,
  selectIsFetching as selectIsFetchingBranches,
  resetData as refreshBranchList,
  selectIsLoaded as selectIsBranchesLoaded,
} from "stores/branches/branchesSlice";
import {
  fetchOndemandRequested,
  selectOndemandSite,
  createOndemandRequested,
  selectIsFinishedCreating as selectIsOndemandFinishedCreating,
  selectIsSaving as selectOndemandSaving,
  selectSucceeded as selectOndemandSucceeded,
  selectError as selectOndemandError,
  resetSaveState,
} from "stores/ondemand/ondemandSlice";

import Environments from "services/environments";
import { DATE_TIME_FORMATS } from "common/constants";
import useUser from "hooks/useUser";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "none",
    marginTop: theme.spacing(4),
  },
  container: {
    "& .MuiFormControl-root": {
      paddingRight: theme.spacing(2),
      width: "100%",
      paddingBottom: "24px",
    },
  },
  submitButton: {
    float: "right",
  },
  refreshButton: {
    paddingRight: "1rem",
  },
}));

const CreateEnvironment = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useUser();
  const isOrganizationFetching = useSelector(selectIsOrganizationFetching);
  const isFetchingBranches = useSelector(selectIsFetchingBranches);
  const isFinishedCreating = useSelector(selectIsOndemandFinishedCreating);
  const isSaving = useSelector(selectOndemandSaving);
  const succeeded = useSelector(selectOndemandSucceeded);
  const error = useSelector(selectOndemandError);
  const environmentOrganizations = useSelector(selectEnvironmentOrganizations);
  const isOrganizationLoaded = useSelector(selectIsOrganizationLoaded);
  const branches = useSelector(selectBranches);
  const isBranchesLoaded = useSelector(selectIsBranchesLoaded);
  const ondemandSite = useSelector(selectOndemandSite);
  const maxDate = addDays(new Date(), 60);

  const sourceEnvironments = Object.keys(Environments).filter(
    (env) => "ondemand" !== env
  );

  const products = {
    enroll: "Enroll",
    sync: "Sync Service",
  };

  const productLinks = {
    enroll: "demand.schoolmint.com",
    sync: "sync.demand.schoolmint.com",
  };

  const repos = [
    "enrollment-be",
    "enrollment-be-custom",
    "enrollment-fe",
    "enrollment-fe-custom",
    "sis-service",
    "sis-service-fe",
  ];

  const initialFormState = {
    product: null,
    fromEnvironment: null,
    organization: null,
    beBranch: null,
    beCustomBranch: null,
    feBranch: null,
    feCustomBranch: null,
    deidentify: true,
    expiryDate: addDays(new Date(), 3),
  };

  const [formValues, setFormValues] = React.useState(initialFormState);
  const [pageState, setPageState] = React.useState("create");

  const isCreateState = pageState === "create";
  const isProgressState = pageState === "progress";

  React.useEffect(() => {
    Object.keys(products).forEach((product) => {
      sourceEnvironments.forEach((environment) => {
        if (
          !environmentOrganizations.hasOwnProperty(product) ||
          !environmentOrganizations[product].hasOwnProperty(environment)
        ) {
          dispatch(
            fetchOrganizationsForEnvironmentRequested({
              environment,
              product,
            })
          );
        }
      });
    });
    // eslint-disable-next-line
  }, [dispatch, isOrganizationLoaded]);

  React.useEffect(() => {
    repos.forEach((repo) => {
      if (!branches.hasOwnProperty(repo)) {
        dispatch(fetchBranchesRequested(repo));
      }
    });
    // eslint-disable-next-line
  }, [dispatch, isBranchesLoaded]);

  React.useEffect(() => {
    if (succeeded && !isFinishedCreating) {
      setTimeout(() => {
        dispatch(fetchOndemandRequested())
      }, 5000);
      setPageState("progress");
    } else {
      setPageState("create");
    }
  }, [dispatch, succeeded, isFinishedCreating]);

  React.useEffect(() => {
    return () => {
      dispatch(resetSaveState());
    };
  }, [dispatch]);

  const buildSchema = () =>
    yup.object().shape({
      product: yup.object().typeError("Required").required("Required"),
      fromEnvironment: yup.object().typeError("Required").required("Required"),
      organization: yup
        .object()
        .shape({
          id: yup.number(),
          subdomain: yup.string(),
        })
        .typeError("Required")
        .required("Required"),
      beBranch: yup.object().typeError("Required").required("Required"),
      beCustomBranch: yup
        .object()
        .typeError("Required")
        .when("product", (product, schema) => {
          if (product.value === "enroll") {
            return schema.required("Required");
          }
          return schema.nullable();
        }),
      feBranch: yup.object().typeError("Required").required("Required"),
      feCustomBranch: yup
        .object()
        .typeError("Required")
        .when("product", (product, schema) => {
          if (product.value === "enroll") {
            return schema.required("Required");
          }
          return schema.nullable();
        }),
      deidentify: yup
        .boolean()
        .typeError("Required")
        .when("product", {
          is: "enroll",
          then: yup.boolean().required("Required"),
          otherwise: yup.boolean(),
        }),
      expiryDate: yup
        .date()
        .min(new Date(), "Expiration cannot be in the past.")
        .max(maxDate, "Expiration cannot be more than 60 days in the future.")
        .typeError("Required")
        .required("Required"),
    });

  const buildOptions = (optionsObj) => {
    const options = Object.entries(optionsObj).map(([value, label]) => {
      return {
        value,
        label,
      };
    });

    return options;
  };

  const buildEnvironmentOptions = () => {
    const options = sourceEnvironments.map((env) => ({
      value: env,
      label: env.charAt(0).toUpperCase() + env.slice(1),
    }));

    return options.sort((a, b) => -b.label.localeCompare(a.label));
  };

  const buildOrganizationOptions = () => {
    if (
      isOrganizationFetching ||
      !formValues.product ||
      !formValues.fromEnvironment ||
      !environmentOrganizations.hasOwnProperty(formValues.product) ||
      !environmentOrganizations[formValues.product].hasOwnProperty(
        formValues.fromEnvironment
      )
    ) {
      return [];
    }

    const options = environmentOrganizations[formValues.product][
      formValues.fromEnvironment
    ].map((org) => {
      let formattedOrg = {};
      if (formValues.product === "enroll") {
        formattedOrg = {
          value: org.id,
          label: `${org.id} - ${org.organization_name} (${org.subdomain})`,
          subdomain: org.subdomain,
          organization_name: org.organization_name,
        };
      } else if (formValues.product === "sync") {
        formattedOrg = {
          value: org.id,
          label: `${org.id} - ${org.subdomain}`,
          subdomain: org.subdomain,
        };
      }

      return formattedOrg;
    });

    return options;
  };

  const buildBranchOptions = (repo) => {
    if (isFetchingBranches || branches[repo] === undefined) {
      return [];
    }

    return branches[repo].map((item) => ({ value: item, label: item }));
  };

  const buildOndemandLink = (product) => {
    if (ondemandSite.hasOwnProperty("subdomain")) {
      return `http://${ondemandSite.subdomain}.${productLinks[product]}`;
    } else {
      return "";
    }
  };

  const isProductSelected = formValues.product && formValues.product.length > 0;

  const {
    handleSubmit,
    control,
    resetField,
    formState: { errors },
  } = useForm({
    defaultValues: initialFormState,
    resolver: yupResolver(buildSchema()),
  });

  const handleFieldChange = (input, newValue) => {
    const updatedformValues = {
      ...formValues,
      [input]: newValue,
    };
    setFormValues(updatedformValues);
  };

  const handleCreateOndemand = (formData) => {
    let payload = {
      email: user.email
    };
    Object.keys(formData).forEach((inputKey) => {
      if (inputKey === "expiryDate") {
        payload[inputKey] = DateFormat(
          formData[inputKey],
          DATE_TIME_FORMATS.outputDate
        );
      } else if (inputKey === "deidentify") {
        if (formData["product"] === "sync") {
          payload[inputKey] = 1;
        } else {
          payload[inputKey] = Number(formData[inputKey]);
        }
      } else if (inputKey === "organization") {
        payload["orgId"] = formData[inputKey].value;
        payload["orgSubdomain"] = formData[inputKey].subdomain;
      } else if (
        formData.hasOwnProperty(inputKey) &&
        null !== formData[inputKey]
      ) {
        payload[inputKey] = formData[inputKey].value;
      }
    });
    dispatch(createOndemandRequested(payload));
  };

  const handleProductChange = (_, newValue) => {
    resetField("organization", { keepError: true });
    resetField("beBranch", { keepError: true });
    resetField("beCustomBranch", { keepError: true });
    resetField("feBranch", { keepError: true });
    resetField("feCustomBranch", { keepError: true });
    resetField("deidentify", { defaultValue: true });
    handleFieldChange("product", newValue);
  };

  const handleOrganizationChange = (selectedValue) => {
    handleFieldChange("organization", {
      id: selectedValue.value,
      subdomain: selectedValue.subdomain,
    });
  };

  const handleAutocompleteOnChange = (event, selectedValue, field) =>
    selectedValue
      ? handleFieldChange(field, String(selectedValue.value))
      : handleFieldChange(field, "");

  const handleRefreshData = () => {
    dispatch(refreshBranchList());
    dispatch(refreshOrganizationList());
  };

  const getErrorBanner = () => {
    if (error) {
      return (
        <Alert severity="error">
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      );
    }
  };

  const getSuccessBanner = () => {
    if (isFinishedCreating && ondemandSite.SK) {
      return (
        <Alert severity="success">
          Ondemand Site Created Successfully, visit:{" "}
          <Link target="_blank" href={buildOndemandLink(ondemandSite.product)}>
            {buildOndemandLink(ondemandSite.product)}
          </Link>
        </Alert>
      );
    }
  };

  const getProgressBanner = () => {
    if (!error && !isFinishedCreating) {
      return (
        <>
          <Alert severity="info">Ondemand site is being created.</Alert>
          <LinearProgress />
        </>
      );
    }
  };

  const renderCreateForm = () => (
    <>
      {isProductSelected ? (
        <>
          <Grid item>
            <FormSelect
              options={buildEnvironmentOptions()}
              name="fromEnvironment"
              label="Source Environment"
              errors={errors}
              isDisabled={isSaving || !isFinishedCreating}
              onChange={handleFieldChange}
              control={control}
              description="Environment to extract data from for the new Ondemand site."
            />
          </Grid>

          <Grid item>
            <FormSelect
              options={buildOrganizationOptions()}
              name="organization"
              label="Organization"
              errors={errors}
              isDisabled={isSaving || !isFinishedCreating}
              onChange={handleOrganizationChange}
              control={control}
              description="Organization to extract from"
            />
          </Grid>

          <Grid item>
            <FormSelect
              options={
                formValues.product === "enroll"
                  ? buildBranchOptions("enrollment-be")
                  : buildBranchOptions("sis-service")
              }
              name="beBranch"
              label="Back End Branch"
              errors={errors}
              isDisabled={isSaving || !isFinishedCreating}
              onChange={handleAutocompleteOnChange}
              control={control}
              description="Back end branch to checkout for on demand environment"
            />
          </Grid>

          {formValues.product === "enroll" ? (
            <Grid item>
              <FormSelect
                options={buildBranchOptions("enrollment-be-custom")}
                name="beCustomBranch"
                label="Custom Back End Branch"
                errors={errors}
                isDisabled={isSaving || !isFinishedCreating}
                onChange={handleAutocompleteOnChange}
                control={control}
                description="Custom Back end branch to checkout for on demand environment"
              />
            </Grid>
          ) : (
            <></>
          )}

          <Grid item>
            <FormSelect
              options={
                formValues.product === "enroll"
                  ? buildBranchOptions("enrollment-fe")
                  : buildBranchOptions("sis-service-fe")
              }
              name="feBranch"
              label="Front End Branch"
              errors={errors}
              isDisabled={isSaving || !isFinishedCreating}
              onChange={handleAutocompleteOnChange}
              control={control}
              description="Front end branch to checkout for on demand environment"
            />
          </Grid>
          {formValues.product === "enroll" ? (
            <Grid item>
              <FormSelect
                options={buildBranchOptions("enrollment-fe-custom")}
                name="feCustomBranch"
                label="Custom Front End Branch"
                errors={errors}
                isDisabled={isSaving || !isFinishedCreating}
                onChange={handleAutocompleteOnChange}
                control={control}
                description="Custom Front end branch to checkout for on demand environment"
              />
            </Grid>
          ) : (
            <></>
          )}

          <Grid item>
            <FormDatePicker
              name="expiryDate"
              label="Expiration Date"
              value={formValues.expiryDate}
              onChange={handleFieldChange}
              control={control}
              errors={errors}
              disablePast={true}
              maxDate={maxDate}
              isDisabled={isSaving || !isFinishedCreating}
              defaultValue={formValues.expiryDate}
              description="The last day this environment will be available"
            />
          </Grid>

          {formValues.product === "enroll" ? (
            <Grid item>
              <FormCheckbox
                name="deidentify"
                label="De-identify data?"
                defaultChecked={formValues.deidentify}
                onChange={handleFieldChange}
                control={control}
                errors={errors}
                isDisabled={isSaving || !isFinishedCreating}
              />
            </Grid>
          ) : (
            <></>
          )}

          <Grid item>
            <Box>
              <FormButton
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                value={isFinishedCreating ? "Create" : "Creating..."}
                isSubmitting={isSaving || !isFinishedCreating}
                disableElevation
              />
            </Box>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  );

  const renderCreateState = () => (
    <>
    <Grid container justifyContent="center" alignItems="center">
      <Grid item lg={6} sm={12}>
        {getErrorBanner()}
        {getSuccessBanner()}
        {getProgressBanner()}
      </Grid>
    </Grid>
    <Grid container justifyContent="center">
      <Grid item xs={6}>
        <Box width="inherit" mt={2}>
          <form onSubmit={handleSubmit(handleCreateOndemand)}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <FormSelect
                  options={buildOptions(products)}
                  name="product"
                  label="Select Product"
                  errors={errors}
                  isDisabled={isSaving || !isFinishedCreating}
                  onChange={handleProductChange}
                  control={control}
                  description="Product to create a new Ondemand site for"
                />
              </Grid>

              {isOrganizationLoaded && isBranchesLoaded ? (
                renderCreateForm()
              ) : (
                <Grid
                  container
                  justify-xs-center="true"
                  direction="row"
                  className={classes.container}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: "100vh" }}
                  >
                    <Grid item>
                      <CircularProgress />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </form>
        </Box>
      </Grid>
    </Grid>
    </>
  );

  const renderProgressState = () => (
    <Grid container justifyContent="center">
      <Grid container justifyContent="center">
        <Grid item xs={6}>
          <Box width="inherit" mt={4}>
            <Grid container direction="column">
              <TrackableProgress
                progressType="boolean"
                label="Creating Infrastructure"
                currentValue={ondemandSite.state === 'complete'}
                completeValue={true}
              />
              <TrackableProgress
                progressType="numeric"
                label="Exporting Organization Data"
                currentValue={ondemandSite.export_progress}
                completeValue={ondemandSite.export_total}
              />
              <TrackableProgress
                progressType="numeric"
                label="Importing Organization Data"
                currentValue={ondemandSite.import_progress}
                completeValue={ondemandSite.import_total}
              />
              <TrackableProgress
                progressType="boolean"
                label="Running Data Migrations"
                currentValue={ondemandSite.be_pipeline_status === 'Succeeded'}
                completeValue={true}
              />
              <TrackableProgress
                progressType="boolean"
                label="Building UI"
                currentValue={ondemandSite.fe_pipeline_status === 'Succeeded'}
                completeValue={true}
              />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid container item justifyContent="space-between" xs={6}>
        <Grid item>
          <Box width="inherit" mt={3}>
            <Typography variant="h4" align="center" color="primary">
              Create New On-Demand Site
            </Typography>
          </Box>
        </Grid>
        {isProgressState ? (
          <></>
        ) : (
          <Grid item>
            <Box width="inherit" mt={2}>
              <Tooltip title="Refresh Lists">
                <IconButton
                  color="primary"
                  onClick={handleRefreshData}
                  size="large"
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        )}
      </Grid>

      {isProgressState ? renderProgressState() : renderCreateState()}
    </Grid>
  );
};

export default CreateEnvironment;
