import React from "react";
import PropTypes from "prop-types";
import { CircularProgress, Box, Typography, Grid } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import useStyles from "./style";

function TrackableProgress({
  progressType,
  label,
  currentValue,
  completeValue,
  error,
}) {
  const classes = useStyles();

  const finished = () => {
    if ("boolean" === progressType) {
      return currentValue === completeValue;
    }
    else if ("numeric" === progressType) {
      return parseInt(currentValue) >= parseInt(completeValue);
    }
  };

  const renderBoolean = () => {
    return <CircularProgress size='2rem' />;
  };

  const renderNumeric = () => {

    const percentage = (parseInt(currentValue) / parseInt(completeValue)) * 100;
    const variant = (currentValue === 0 ? "indeterminate":"determinate");
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>

      <CircularProgress size='2rem' variant={variant} value={percentage}/>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(percentage)}%`}</Typography>
      </Box>
      </Box>
    );
  };

  const renderProgress = () => {
    if (finished()) {
      return <CheckIcon color="success" />;
    }

    if ("boolean" === progressType) {
      return renderBoolean();
    }
    else if ("numeric" === progressType) {
      return renderNumeric();
    }
  };

  return (
    <Box mt={4} mb={1}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="subtitle">{label}</Typography>
        </Grid>
        <Grid item>{renderProgress()}</Grid>
      </Grid>
    </Box>
  );
}

TrackableProgress.propTypes = {
  progressType: PropTypes.string.isRequired,
  label: PropTypes.string,
  currentValue: PropTypes.any,
  completeValue: PropTypes.any,
  error: PropTypes.bool,
};

TrackableProgress.defaultProps = {
  progressType: "boolean",
  currentValue: false,
  completeValue: true,
  error: false,
};

export default TrackableProgress;
